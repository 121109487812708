import React from 'react';
import Repeater from '../../../components/repeater/Repeater';
import { MainRepeater, StyledContentWrapper } from './ServiceOffers.style';
import CardServiceOffer from '../../../components/cards/card-service-offer/CardServiceOffer';

interface ServicesOffersProps {
  cards: any;
  size: any;
}

const ServicesOffers: React.FC<any> = ({
  data,
}: {
  data: ServicesOffersProps;
}) => {
  return (
    <StyledContentWrapper>
      <MainRepeater>
        <Repeater
          spacing={1}
          cols={{ xs: 12, sm: 6, md: 6, lg: 3, xl: 3 }}
          data={data.cards}
          component={CardServiceOffer}
          align={'center'}
        />
      </MainRepeater>
    </StyledContentWrapper>
  );
};

export default ServicesOffers;
